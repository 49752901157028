import { NativeDateAdapter } from '@angular/material/core'

export class NavixDateAdapter extends NativeDateAdapter {
  public constructor(matDateLocale: string) {
    super(matDateLocale)
  }

  override parse(value: any, parseFormat?: any): Date | null {
    const useTwoDigitParse =
      (value?.length === 8 || value?.length === 6) &&
      value?.match(/^\d+$/) != null
    const newValue = useTwoDigitParse ? this.addSpace(value) : value
    return super.parse(newValue, parseFormat)
  }
  addSpace(value: string) {
    const regex = /(..)(..)(..)/
    return value.replace(regex, '$1 $2 $3')
  }
}
