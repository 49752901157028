<section class="w-full sm:max-w-md">
  <img
    ngSrc="https://fpasaprodue01sa.blob.core.windows.net/assets/logo.svg"
    alt=""
    fill
    class="!h-8 !w-36 !mb-4 !relative" />
  <h1 class="py-2 font-medium">Forgot password?</h1>
  <span class="py-2 text-sm">
    No worries, we'll send you reset instructions.
  </span>

  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="grid grid-cols-2 grid-flow-row py-2 gap-2">
    <label for="email" class="text-xs">Email</label>
    <input
      id="email"
      formControlName="email"
      type="email"
      class="col-span-2 form-control" />
    <button
      type="submit"
      class="col-span-2 btn btn-primary"
      [disabled]="loading()">
      <span *ngIf="loading()" class="loading loading-spinner loading-sm"></span>
      Send Recovery Email
    </button>
    <button
      type="button"
      class="col-span-2 btn btn-ghost"
      [routerLink]="['/', 'login']">
      Back to sign in
    </button>
  </form>
</section>
