import { inject } from '@angular/core'
import { CanActivateFn, Router } from '@angular/router'
import { AuthService } from '@auth0/auth0-angular'
import { map, take, tap } from 'rxjs'

export const isAuthenticatedGuard: CanActivateFn = () => {
  const authService = inject(AuthService)
  const router = inject(Router)

  return authService.isAuthenticated$.pipe(
    take(1),
    tap(isAuthenticated => {
      if (isAuthenticated) return
      window.sessionStorage.setItem('redirect', window.location.pathname)
    }),
    map(isAuthenticated =>
      isAuthenticated ? isAuthenticated : router.createUrlTree(['/', 'login'])
    )
  )
}
