import { makeEnvironmentProviders } from '@angular/core'
import { AuthHttpInterceptor, provideAuth0 } from '@auth0/auth0-angular'
import { environment } from '@navix/shared/environments'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { AUTH_CLIENT_ID, AUTH_URL } from '@navix/utils/tokens'

export function provideAuth() {
  return makeEnvironmentProviders([
    provideAuth0({
      domain: environment.auth.domain,
      clientId: environment.auth.clientId,
      errorPath: environment.auth.errorPath,
      authorizationParams: {
        audience: environment.auth.audience,
        apiUri: environment.auth.apiUri,
        appUri: environment.auth.appUri,
        redirect_uri: window.location.origin
      },
      httpInterceptor: {
        allowedList: environment.httpInterceptorList
      }
    }),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true
    },
    {
      provide: AUTH_URL,
      useValue: environment.auth.domain
    },
    {
      provide: AUTH_CLIENT_ID,
      useValue: environment.auth.clientId
    }
  ])
}
