import { Route } from '@angular/router'
import { isAuthenticatedGuard } from '../guards/is-authenticated/is-authenticated.guard'
import { isCurrentTenantId } from '../guards/is-current-tenant-id/is-current-tenant-id.guard'
import { LoginComponent } from './auth/login/login.component'
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component'
import { CheckEmailComponent } from './auth/check-email/check-email.component'
import { USE_ORDER_CENTRIC } from '@navix/utils/tokens'

export const appRoutes: Route[] = [
  {
    path: '',
    canActivateChild: [isAuthenticatedGuard],
    loadComponent: () => import('./base-layout/base-layout.component'),
    children: [
      {
        path: '',
        loadComponent: () => import('./layout-logged/layout-logged.component'),
        canActivateChild: [isCurrentTenantId],
        runGuardsAndResolvers: 'always',
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'pre-audit'
          },
          {
            path: 'monolith',
            loadChildren: () => import('./app-monolith/app-monolith.routes')
          },
          {
            path: 'tenant-admin',
            loadChildren: () => import('./routes/tenant-admin.routes')
          },
          {
            path: 'navix-admin',
            loadChildren: () => import('./routes/navix-admin.routes')
          },
          {
            path: 'pre-audit',
            providers: [
              {
                provide: USE_ORDER_CENTRIC,
                useValue: true
              }
            ],
            loadChildren: () => import('./routes/pre-audit.routes')
          },
          {
            path: 'audit',
            providers: [
              {
                provide: USE_ORDER_CENTRIC,
                useValue: true
              }
            ],
            loadChildren: () => import('./routes/audit.routes')
          },
          {
            path: 'analysis',
            providers: [
              {
                provide: USE_ORDER_CENTRIC,
                useValue: true
              }
            ],
            loadChildren: () => import('./routes/analysis.routes')
          },
          {
            path: 'd',
            outlet: 'dialog',
            children: [
              {
                path: 'current-user',
                loadChildren: () =>
                  import('@navix/current-user/shell').then(m => m.dialogRoutes)
              }
            ]
          }
        ]
      },
      {
        path: 'standalone',
        loadChildren: () => import('./routes/standalone.routes')
      }
    ]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'check-email', component: CheckEmailComponent },
  {
    path: '**',
    redirectTo: 'login'
  }
]
