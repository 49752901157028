import {
  API_URL,
  API_URL_IDENTITY,
  API_URL_NO_DEFAULT_VERSION,
  API_URL_V1_1_VERSION,
  API_URL_V1_2_VERSION,
  API_URL_V2_1_VERSION,
  API_URL_V2_VERSION,
  HEADER_API_KEY
} from '@navix/utils/tokens'
import { environment } from '@navix/shared/environments'
import { makeEnvironmentProviders } from '@angular/core'
import { OdataParser, QUERY_PARSER } from '@navix/utils/query-parser'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import { AddTenantUuidHeaderInterceptor } from './interceptors/add-tenant-uuid-header.interceptor'

export function provideApi() {
  return makeEnvironmentProviders([
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddTenantUuidHeaderInterceptor,
      multi: true
    },
    {
      provide: QUERY_PARSER,
      useClass: OdataParser
    },
    {
      provide: API_URL,
      useValue: `${environment.apis.management}v1/`
    },
    {
      provide: API_URL_IDENTITY,
      useValue: environment.apis.identity
    },
    {
      provide: HEADER_API_KEY,
      useValue: { 'Ocp-Apim-Subscription-Key': environment.apiKey.management }
    },
    {
      provide: API_URL_V1_1_VERSION,
      useValue: `${environment.apis.management}v1.1/`
    },
    {
      provide: API_URL_V1_2_VERSION,
      useValue: `${environment.apis.management}v1.2/`
    },
    {
      provide: API_URL_V2_VERSION,
      useValue: `${environment.apis.management}v2/`
    },
    {
      provide: API_URL_V2_1_VERSION,
      useValue: `${environment.apis.management}v2.1/`
    },
    {
      provide: API_URL_NO_DEFAULT_VERSION,
      useValue: `${environment.apis.management}`
    }
  ])
}
