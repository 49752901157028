import { makeEnvironmentProviders } from '@angular/core'
import { EMAIL_VALIDATOR } from '@navix/utils/tokens'
import { environment } from '@navix/shared/environments'
const PROD = 'PROD' as const
export function provideCustomValidations() {
  return makeEnvironmentProviders([
    {
      provide: EMAIL_VALIDATOR,
      useValue:
        environment.env === PROD
          ? /^[\w.%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
          : /^[\w.%+-]+@navix.io$/i
    }
  ])
}
