import { ErrorHandler, makeEnvironmentProviders } from '@angular/core'
import { environment } from '@navix/shared/environments'
import { ApplicationinsightsAngularpluginErrorService } from '@microsoft/applicationinsights-angularplugin-js'

export function provideErrorHandler() {
  return makeEnvironmentProviders([
    {
      provide: ErrorHandler,
      useClass:
        environment.appInsights.connectionString !== ''
          ? ApplicationinsightsAngularpluginErrorService
          : ErrorHandler
    }
  ])
}
