import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest
} from '@angular/common/http'
import { Observable, switchMap, take } from 'rxjs'
import { Injectable, inject } from '@angular/core'
import { CurrentUserFacade } from '@navix/current-user/domain'

@Injectable()
export class AddTenantUuidHeaderInterceptor implements HttpInterceptor {
  currentUserFacade = inject(CurrentUserFacade)

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const whiteList = ['/auth/', 'tenants/current', '/identity/']

    if (whiteList.some(url => req.url.includes(url))) return next.handle(req)

    return this.currentUserFacade.currentTenant$.pipe(
      take(1),
      switchMap(tenant => {
        const clonedRequest = req.clone({
          headers: req.headers.append('X-Tenant-Uuid', tenant.uuid)
        })
        return next.handle(clonedRequest)
      })
    )
  }
}
