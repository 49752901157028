/* eslint-disable @nx/enforce-module-boundaries */
import { Component, Injector, OnInit, inject } from '@angular/core'
import { Router, RouterOutlet } from '@angular/router'
import { environment } from '@navix/shared/environments'
import { AngularPlugin } from '@microsoft/applicationinsights-angularplugin-js'
import { DefaultAngularErrorHandlerWrapper } from '../handlers/default-angular-error-handler-wrapper'
import { ApplicationInsights } from '@microsoft/applicationinsights-web'

@Component({
  standalone: true,
  imports: [RouterOutlet],
  selector: 'navix-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly router = inject(Router)
  private readonly injector = inject(Injector)

  ngOnInit(): void {
    try {
      this.initializeApplicationInsights()
    } catch (e) {
      console.error('Cannot initialize Application Insights', e)
    }
  }

  private initializeApplicationInsights() {
    if (environment.appInsights.connectionString === '') return
    const angularPlugin = new AngularPlugin(this.injector)
    const appInsights = new ApplicationInsights({
      config: {
        connectionString: environment.appInsights.connectionString,
        extensions: [angularPlugin],
        extensionConfig: {
          [angularPlugin.identifier]: {
            router: this.router,
            errorServices: [new DefaultAngularErrorHandlerWrapper()]
          }
        },
        enableCorsCorrelation: false,
        enableAutoRouteTracking: true,
        autoTrackPageVisitTime: true,
        enableRequestHeaderTracking: true
      }
    })
    appInsights.loadAppInsights()
  }
}
