import { importProvidersFrom, makeEnvironmentProviders } from '@angular/core'
import { NgxPendoModule } from 'ngx-pendo'
import { environment } from '@navix/shared/environments'
import {
  LAUNCH_DARKLY_CLIENT_ID,
  PS_PDF_KIT_BASE_CONFIG
} from '@navix/utils/tokens'

export function provideThirdParty() {
  return makeEnvironmentProviders([
    importProvidersFrom(
      NgxPendoModule.forRoot({
        pendoApiKey: environment.pendo.key,
        pendoIdFormatter: (value: any) => value.toString().toLowerCase()
      })
    ),
    {
      provide: LAUNCH_DARKLY_CLIENT_ID,
      useValue: environment.launchDarkly.clientSideId
    },
    {
      provide: PS_PDF_KIT_BASE_CONFIG,
      useValue: {
        baseUrl: location.protocol + '//' + location.host + '/assets/',
        licenseKey: environment.pspdfkit.licenseKey
      }
    }
  ])
}
