<navix-dialog-base
  class="max-w-xl"
  [defaultConfiguration]="{ title: 'Redirect to tenant' }"
  *ngIf="vm$ | ngrxPush as vm">
  <ng-template #body>
    <span class="px-4 txt-subtitle">
      Are you sure you want to switch to
      <strong>{{ vm.currentUserTenant?.description }}</strong
      >?
    </span>
  </ng-template>

  <ng-template #footer>
    <button
      type="button"
      class="btn btn-outline-gray-500 w-24"
      (click)="closeDialog()">
      Cancel
    </button>
    <button type="button" class="btn btn-primary w-24" (click)="switchTenant()">
      @if (isLoading()) {
        <span class="loading loading-spinner loading-md"></span>
      }
      Switch
    </button>
  </ng-template>
</navix-dialog-base>
