<navix-dialog-base
  class="max-w-xl"
  [defaultConfiguration]="{ title: 'Access denied' }">
  <ng-template #body>
    <span class="px-4 txt-subtitle">
      Access denied, you do not have access to this Tenant.
    </span>
  </ng-template>

  <ng-template #footer>
    <button
      type="button"
      class="btn btn-primary w-24"
      (click)="redirectToList()">
      Ok
    </button>
  </ng-template>
</navix-dialog-base>
