import { importProvidersFrom, makeEnvironmentProviders } from '@angular/core'
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MAT_NATIVE_DATE_FORMATS,
  MatNativeDateModule
} from '@angular/material/core'
import { NavixDateAdapter } from './navix-date-adapter'

export function provideNavixMaterialCommons() {
  return makeEnvironmentProviders([
    importProvidersFrom([MatNativeDateModule]),
    {
      provide: DateAdapter,
      useClass: NavixDateAdapter,
      deps: [MAT_DATE_LOCALE]
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: {
        ...MAT_NATIVE_DATE_FORMATS,
        display: {
          ...MAT_NATIVE_DATE_FORMATS.display,
          dateInput: <Intl.DateTimeFormatOptions>{
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
          }
        }
      }
    }
  ])
}
