import { DialogModule } from '@angular/cdk/dialog'
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import {
  APP_INITIALIZER,
  importProvidersFrom,
  makeEnvironmentProviders
} from '@angular/core'

import { AuthService } from '@auth0/auth0-angular'
import { provideAlertsFeature } from '@navix/alerts/domain'
import {
  CurrentUserFacade,
  provideCurrentUserFeature
} from '@navix/current-user/domain'
import { provideCustomersFeature } from '@navix/customers/domain'
import { provideDocumentsFeature } from '@navix/documents/domain'
import {
  provideAuditsFeature,
  provideInvoicesFeature
} from '@navix/orders/domain'
import { provideMiscellaneousFeature } from '@navix/miscellaneous/domain'
import { provideOrdersFeature } from '@navix/orders/domain'
import { environment } from '@navix/shared/environments'
import { provideUsersFeature } from '@navix/users/domain'
import { provideVendorsFeature } from '@navix/vendors/domain'
import { providChargeCodesFeature } from '@navix/charge-codes/domain'

import { provideAnimations } from '@angular/platform-browser/animations'
import { RouteReuseStrategy } from '@angular/router'
import {
  RouterState,
  provideRouterStore,
  routerReducer
} from '@ngrx/router-store'
import { provideStore } from '@ngrx/store'
import { provideStoreDevtools } from '@ngrx/store-devtools'
import { firstValueFrom } from 'rxjs'
import { provideApi } from './api.provider'
import { provideAssets } from './assets.provider'
import { provideAuth } from './auth.provider'
import { provideCustomValidations } from './custom-validations.provider'
import { provideErrorHandler } from './error-handler.provider'
import { CustomRouteReuseStrategy } from './strategies/route-reuse-strategy'
import { provideThirdParty } from './third-party.provider'
import { provideNavixMaterialCommons } from './navix-material-commons.provider'
import { provideApiCodegen } from '@navix/api-models'
import { provideServicesApiCodegen } from '@navix/services-api-models'
import { provideRolesFeature } from '@navix/roles/domain'
import { provideDisputesFeature } from '@navix/disputes/domain'
import { RouteReuseWithSegmentsStrategy } from './strategies/route-reuse-with-segments-strategy'

function loadCurrentUser(
  currentUserFacade: CurrentUserFacade,
  authService: AuthService
) {
  return () =>
    firstValueFrom(authService.isAuthenticated$).then(async result => {
      if (result === false) return
      currentUserFacade.loadCurrentUser()
      currentUserFacade.loadCurrentUserMenu()
      await currentUserFacade.initializeFeatureFlags()
    })
}
export function provideNavixApplicationCommons() {
  return makeEnvironmentProviders([
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: loadCurrentUser,
      deps: [CurrentUserFacade, AuthService]
    },
    provideNavixMaterialCommons(),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations(),
    importProvidersFrom([DialogModule]),
    //Navix Providers
    //API Codegen
    provideApiCodegen(environment.apis.management.slice(0, -5)),
    provideServicesApiCodegen(environment.apis.management.slice(0, -5)),
    provideAuth(),
    provideApi(),
    provideAssets(),
    provideErrorHandler(),
    provideCustomValidations(),
    provideThirdParty(),
    //Store Providers
    provideStore({
      router: routerReducer
    }),
    provideStoreDevtools({
      logOnly: environment.production,
      connectInZone: true
    }),
    provideRouterStore({
      routerState: RouterState.Minimal
    }),

    provideCurrentUserFeature(),
    provideAlertsFeature(),
    provideMiscellaneousFeature(),
    provideUsersFeature(),
    provideVendorsFeature(),
    provideCustomersFeature(),
    provideOrdersFeature(),
    provideDocumentsFeature(),
    provideInvoicesFeature(),
    providChargeCodesFeature(),
    provideRolesFeature(),
    provideDisputesFeature(),
    provideAuditsFeature(),

    { provide: RouteReuseStrategy, useClass: CustomRouteReuseStrategy },
    { provide: RouteReuseStrategy, useClass: RouteReuseWithSegmentsStrategy }
  ])
}
