import { Dialog } from '@angular/cdk/dialog'
import { inject } from '@angular/core'
import {
  ActivatedRouteSnapshot,
  CanActivateChildFn,
  createUrlTreeFromSnapshot
} from '@angular/router'
import { CurrentUserFacade } from '@navix/current-user/domain'

import { filter, map, switchMap } from 'rxjs'
import { RedirectTenantDialogComponent } from './redirect-tenant-dialog/redirect-tenant-dialog.component'
import { AccessTenantDialogComponent } from './access-tenant-dialog/access-tenant-dialog.component'

export const isCurrentTenantId: CanActivateChildFn = (
  childRoute: ActivatedRouteSnapshot,
  state
) => {
  const currentUserFacade = inject(CurrentUserFacade)
  const dialog = inject(Dialog)

  const queryParamTenantUuid = childRoute.queryParams['tenantUuid'] as
    | string
    | undefined
  const currentTenantUuid = currentUserFacade.currentTenant()?.uuid
  const newRouteWithTenantUuid = createUrlTreeFromSnapshot(childRoute, [], {
    tenantUuid: currentTenantUuid
  })
  const redirectRouteWithTenantUuid = createUrlTreeFromSnapshot(
    childRoute,
    ['/'],
    {
      tenantUuid: currentTenantUuid
    }
  )
  if (currentTenantUuid === undefined) {
    return currentUserFacade.currentTenant$.pipe(
      filter(tenant => tenant !== undefined),
      map(() => true)
    )
  }

  if (
    queryParamTenantUuid === undefined ||
    queryParamTenantUuid.length !== currentTenantUuid.length
  ) {
    return newRouteWithTenantUuid
  }

  if (queryParamTenantUuid !== currentTenantUuid) {
    console.log('queryParamTenantUuid', childRoute, state)
    return currentUserFacade.allCurrentUserTenants$.pipe(
      map(
        tenants =>
          tenants.find(tenant => tenant.uuid === queryParamTenantUuid)?.id
      ),
      switchMap(newTenantId =>
        newTenantId === undefined
          ? dialog
              .open(AccessTenantDialogComponent)
              .closed.pipe(map(() => redirectRouteWithTenantUuid))
          : dialog
              .open(RedirectTenantDialogComponent, {
                data: { tenantId: newTenantId }
              })
              .closed.pipe(map(() => newRouteWithTenantUuid))
      )
    )
  }

  return true
}
