import { ASSETS_URL } from '@navix/utils/tokens'
import { environment } from '@navix/shared/environments'
import { makeEnvironmentProviders } from '@angular/core'

export function provideAssets() {
  return makeEnvironmentProviders([
    {
      provide: ASSETS_URL,
      useValue: environment.assets
    }
  ])
}
