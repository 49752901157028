<section class="w-full sm:max-w-md">
  <img
    [src]="mainLargeLogo"
    alt=""
    fill
    class="!h-auto !w-36 !mb-4 !relative" />
  <h1 class="py-2 font-medium">Sign In</h1>
  <span class="py-2 text-sm"> Welcome back! Please enter your details. </span>

  <form
    [formGroup]="form"
    (ngSubmit)="onSubmit()"
    class="grid grid-cols-2 grid-flow-row py-2 gap-2">
    <label for="email" class="text-xs">Email</label>
    <input
      id="email"
      formControlName="email"
      type="email"
      class="col-span-2 form-control" />
    <button
      type="submit"
      class="col-span-2 btn btn-primary"
      [disabled]="loading()">
      <span *ngIf="loading()" class="loading loading-spinner loading-sm"></span>
      Next
    </button>
  </form>

  @if (showLoginErrorMessage()) {
    <span class="py-2 text-sm">
      <em class="text-danger">
        User does not have an active Navix account. Contact your Navix
        representative for account creation
      </em>
    </span>
  } @else {
    <span class="py-2 text-sm">
      <em
        >First time logging in? Enter your email, click 'Next', then 'Forgot
        password' to get started!</em
      >
    </span>
  }
</section>
