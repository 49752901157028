import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  OnDestroy,
  computed,
  effect,
  inject,
  signal
} from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'

import { toSignal } from '@angular/core/rxjs-interop'
import { ActivatedRoute, Router, RouterLink } from '@angular/router'
import { CurrentUserFacade } from '@navix/current-user/domain'
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule
} from '@angular/forms'
import { DynamicValidatorMessageDirective } from '@navix/forms'
import { map } from 'rxjs'
import { LoadingStatuses } from '@navix/shared/loading'

@Component({
  selector: 'navix-check-email',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterLink,
    ReactiveFormsModule,
    DynamicValidatorMessageDirective
  ],
  templateUrl: './check-email.component.html',
  styleUrls: ['./check-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CheckEmailComponent {
  @HostBinding('class') readonly class =
    'h-screen flex flex-col justify-center items-center'

  private readonly router = inject(Router)
  private readonly route = inject(ActivatedRoute)
  private readonly currentUserFacade = inject(CurrentUserFacade)

  email = toSignal<string | undefined>(
    this.route.queryParams.pipe(map(params => params['email'])),
    {
      initialValue: undefined
    }
  )

  onEmptyEmail = effect(() => {
    if (this.email() === undefined) this.router.navigate(['/login'])
  })

  loadingState = toSignal(
    this.currentUserFacade.loading$.pipe(map(loading => loading.resetPassword))
  )

  loading = computed(
    () => this.loadingState()?.status === LoadingStatuses.InProgress
  )

  onRedirectToForgotPassword(): void {
    this.router.navigate(['/forgot-password'])
  }

  onSubmit() {
    if (this.email() === undefined) return
    this.currentUserFacade.resetPassword(this.email() as string)
  }
}
