/* eslint-disable @nx/enforce-module-boundaries */
import { ApplicationConfig } from '@angular/core'

import {
  PreloadAllModules,
  provideRouter,
  withComponentInputBinding,
  withEnabledBlockingInitialNavigation,
  withPreloading,
  withRouterConfig
} from '@angular/router'
import { provideNavixApplicationCommons } from '@navix/shared/providers'
import { appRoutes } from './app.routes'

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      appRoutes,
      withRouterConfig({
        paramsInheritanceStrategy: 'always'
      }),
      withEnabledBlockingInitialNavigation(),
      withComponentInputBinding(),
      withPreloading(PreloadAllModules)
    ),
    provideNavixApplicationCommons()
  ]
}
