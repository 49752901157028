import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  computed,
  effect,
  inject
} from '@angular/core'
import { CommonModule, NgOptimizedImage } from '@angular/common'

import { toSignal } from '@angular/core/rxjs-interop'
import { Router, RouterLink } from '@angular/router'
import { CurrentUserFacade } from '@navix/current-user/domain'
import {
  FormGroup,
  FormControl,
  Validators,
  ReactiveFormsModule
} from '@angular/forms'
import { DynamicValidatorMessageDirective } from '@navix/forms'
import { map } from 'rxjs'
import { LoadingStatuses } from '@navix/shared/loading'
@Component({
  selector: 'navix-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    NgOptimizedImage,
    RouterLink,
    ReactiveFormsModule,
    DynamicValidatorMessageDirective
  ],
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ForgotPasswordComponent {
  @HostBinding('class') readonly class =
    'h-screen flex flex-col justify-center items-center'

  private readonly router = inject(Router)
  private readonly currentUserFacade = inject(CurrentUserFacade)

  loadingState = toSignal(
    this.currentUserFacade.loading$.pipe(map(loading => loading.resetPassword))
  )

  loading = computed(
    () => this.loadingState()?.status === LoadingStatuses.InProgress
  )

  onSuccess = effect(() => {
    if (this.loadingState()?.status === LoadingStatuses.Completed)
      setTimeout(() => {
        this.router.navigate(['/check-email'], {
          queryParams: { email: this.form.value.email },
          skipLocationChange: true
        })
      })
  })

  form = new FormGroup({
    email: new FormControl<string>(
      '',
      Validators.compose([
        Validators.required,
        Validators.maxLength(320),
        Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')
      ])
    )
  })

  onRedirectToForgotPassword(): void {
    this.router.navigate(['/forgot-password'])
  }

  onSubmit() {
    if (this.form.invalid || this.form.value.email == undefined) return
    this.currentUserFacade.resetPassword(this.form.value.email)
  }
}
