<section class="w-full sm:max-w-md">
  <img
    ngSrc="https://fpasaprodue01sa.blob.core.windows.net/assets/logo.svg"
    alt=""
    fill
    class="!h-8 !w-36 !mb-4 !relative" />
  <h1 class="py-2 font-medium">Check your email</h1>
  <span class="py-2 text-sm">
    Please check the email address <b>{{ email() }}</b> for instructions to
    reset your password.
  </span>

  <div (ngSubmit)="onSubmit()" class="flex flex-col py-2 gap-2">
    <button
      type="button"
      class="col-span-2 btn btn-primary"
      [routerLink]="['/', 'login']">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        fill="none"
        viewBox="0 0 24 24"
        stroke="currentColor"
        strokeWidth="{2}">
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M10 19l-7-7m0 0l7-7m-7 7h18" />
      </svg>
      Back to sign in
    </button>
    <div class="flex flex-row text-sm leading-5 justify-center">
      <span class="text-sm leading-5 justify-center">
        Didn’t receive the email?
        <button type="button" (click)="onSubmit()" class="btn btn-link p-0">
          Click to resend.
        </button>
      </span>
    </div>
  </div>
</section>
