import { ChangeDetectionStrategy, Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { DialogRef } from '@angular/cdk/dialog'
import { DialogBaseComponent } from '@navix/ui/common'
import { PushPipe } from '@ngrx/component'

@Component({
  selector: 'navix-access-tenant-dialog',
  standalone: true,
  imports: [CommonModule, DialogBaseComponent, PushPipe],
  templateUrl: './access-tenant-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccessTenantDialogComponent {
  private readonly dialogRef = inject(DialogRef)

  closeDialog() {
    this.dialogRef.close()
  }

  async redirectToList() {
    this.dialogRef.close()
  }
}
