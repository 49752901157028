import {
  RouteReuseStrategy,
  ActivatedRouteSnapshot,
  DetachedRouteHandle
} from '@angular/router'

export class CustomRouteReuseStrategy implements RouteReuseStrategy {
  private routeStore = new Map<string, DetachedRouteHandle>()

  private getUrlKey(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(r => r.url)
      .filter(f => !!f[0])
      .map(([f]) => f.path)
      .join('/')
      .concat(`/${route.component?.name || '-'}`)
  }

  private getUrlDomain(route: ActivatedRouteSnapshot): string {
    return route.pathFromRoot
      .map(r => r.url)
      .filter(f => !!f[0])
      .map(([f]) => f.path)
      .slice(0, 4)
      .join('/')
  }

  private getReuseFlag(route: ActivatedRouteSnapshot): boolean {
    return route.data['reuse'] || false
  }

  shouldDetach(route: ActivatedRouteSnapshot): boolean {
    const reuse = this.getReuseFlag(route)
    return reuse
  }

  store(route: ActivatedRouteSnapshot, handle: DetachedRouteHandle): void {
    const reuse = this.getReuseFlag(route)
    if (reuse) {
      const url = this.getUrlKey(route)
      this.routeStore.set(url, handle)
    }
  }

  isStored(route: ActivatedRouteSnapshot) {
    const url = this.getUrlKey(route)
    const domain = this.getUrlDomain(route)

    Array.from(this.routeStore.keys())
      .filter((key: string) => key.includes(domain) && key !== url)
      .forEach((key: string) => this.routeStore.delete(key))

    return !!this.routeStore.get(url)
  }

  shouldAttach(route: ActivatedRouteSnapshot): boolean {
    const reuse = this.getReuseFlag(route)
    return reuse ? this.isStored(route) : false
  }

  retrieve(route: ActivatedRouteSnapshot): DetachedRouteHandle | null {
    const url = this.getUrlKey(route)
    const stored = this.routeStore.get(url) || null
    return stored
  }

  shouldReuseRoute(
    future: ActivatedRouteSnapshot,
    curr: ActivatedRouteSnapshot
  ): boolean {
    return future.routeConfig === curr.routeConfig
  }
}
