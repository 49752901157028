import {
  ChangeDetectionStrategy,
  Component,
  computed,
  inject
} from '@angular/core'
import { CommonModule } from '@angular/common'
import { DIALOG_DATA, DialogRef } from '@angular/cdk/dialog'
import { DialogBaseComponent } from '@navix/ui/common'
import { CurrentUserFacade } from '@navix/current-user/domain'
import { LoadingStatuses } from '@navix/shared/loading'
import {
  map,
  filter,
  tap,
  ignoreElements,
  startWith,
  combineLatest
} from 'rxjs'
import { PushPipe } from '@ngrx/component'

@Component({
  selector: 'navix-redirect-tenant-dialog',
  standalone: true,
  imports: [CommonModule, DialogBaseComponent, PushPipe],
  templateUrl: './redirect-tenant-dialog.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RedirectTenantDialogComponent {
  private readonly dialogRef = inject(DialogRef)
  private readonly currentUserFacade = inject(CurrentUserFacade)
  private readonly dialogData = inject<{ tenantId: number }>(DIALOG_DATA)

  loading = computed(() => this.currentUserFacade.loading().switchTenant)

  isLoading = computed(
    () => this.loading().status === LoadingStatuses.InProgress
  )

  onSuccessSwitchTenant$ = this.currentUserFacade.loading$.pipe(
    map(loadingState => loadingState.switchTenant),
    filter(loading => loading.status === LoadingStatuses.Completed),
    tap(() => {
      location.reload()
    }),
    ignoreElements(),
    startWith(void 0)
  )

  vm$ = combineLatest([
    this.currentUserFacade.allCurrentUserTenants$,
    this.onSuccessSwitchTenant$
  ]).pipe(
    map(([currentUserTenants]) => ({
      currentUserTenant: currentUserTenants.find(
        tenant => tenant.id === this.dialogData.tenantId
      )
    }))
  )

  closeDialog() {
    this.dialogRef.close()
  }

  async switchTenant() {
    this.currentUserFacade.switchCurrentUserTenant(this.dialogData.tenantId)
  }
}
